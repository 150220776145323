.fullMarkdownPage {
    background-color: var(--background-color);
    height: 100vh;
}

.loginMarkdownDiv {
    padding: 0px 10px;
    position: fixed;
    top: 0;
    right: 10px;
}

.languageSelect {
    padding: 5px;
    border-radius: 5px;
    margin: 12px 10px 0px;
}

.contentPageMd {
    background-color: white;
    overflow: auto;
    height: 100vh;
    font-size: 17px;
}

.contentPageMd p, 
.contentPageMd h1,
.contentPageMd h2,
.contentPageMd h3,
.contentPageMd h4, 
.contentPageMd ul, 
.contentPageMd ol,
.contentPageMd hr
{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.contentPageMd ul, .contentPageMd ol {
    padding-left: 60px;
}

.contentPagePrice h3 {
    padding: 30px; 
}

.priceList {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.priceList table {
    width: 290px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    background-color: white;
    color: black;
    padding: 10px;
    padding-top: 0px;
    border-radius: 10px;
    border-collapse: collapse;
    float: left;
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.breakFloat {
    clear: both;
}

.priceList table td {
    height: 25px;
    padding: 5px 10px;
}

.priceList table th {
    padding: 10px 10px;
    font-size: 20pt;
}

.priceList table td:nth-child(2) {
    text-align: right;
}

.priceList table tr:first-child th {
    border-radius: 10px 10px 0px 0px;
    background-color: var(--accent-color);
    color: white;
}

.priceList table tr:last-child td {
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 10px;
}

.priceList img {
    width: 100%;
    padding-top: 20px;
    border-radius: 5px;
}

.center {
    text-align: center;
}

.backgroundPrice {
    background-image: linear-gradient(#657591b4, #4f70aa1f), url("../images/Pile.jpg");
    background-size: cover;
    background-position: center;
}