@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --accent-color: #77AAFF;
    --accent-light: #e9f1ff;
    --background-color: rgb(240, 240, 240);
}
.App {
  min-height: 100vh;
}

.downloadPage {
  background-color: white;
  min-height: calc(100vh - 105px);
  border: 10px solid transparent;
  border-radius: 20px;
  max-width: 850px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 0px;
}

.fullPage {
  background-color: var(--background-color);
  padding-top: 65px;
  height: calc(100vh - 65px);
}
.contentPage {
    background-color: white;
    overflow: auto;
    height: 100vh;
    font-size: 17px;
}

.contentPage h3 {
    margin: 0;
    text-align: center;
    font-size: 45px;
    line-height: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.contentPage .intro {
    min-height: 550px;
    font-size: 25px;
    color: white;
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(#4f70aab4, #4f70aa1f), url(/static/media/Banner.ab415409.png);
    background-size: cover;
    padding-left: 20px;
    padding-right: 20px;
}

.intro p:last-child {
    padding-bottom: 50px;
}

.contentPage hr {
    background-color: var(--accent-color);
    height: 10px;
    border: none;
    margin: 0;
    margin-top: 35px;
}

.contentPage p, 
.contentPage h4, 
.contentPage ul, 
.contentPage ol, 
/*.contentPage .img, */
.contentPage .video 
{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.contentPage ul, .contentPage ol {
    padding-left: 40px;
}

.contentPage iframe {
    padding: 10px 0;
    width: 100%;
}

.contentPage .textBlock:nth-child(odd) {
    background-color: #555555;
    color: white;
}

.contentPage .textBlock:nth-child(odd):not(.buttonLinks) a {
    color: lightgray;
    text-decoration: none;
}

.contentPage .textBlock:nth-child(even) {
    background-color: rgb(255, 255, 255);
}

.contentPage h4 {
    font-size: 35px;
    margin: 0 auto;
    padding: 20px;
    max-width: 1000px;
}

.contentPage .img img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    object-position: 70% 0;
    margin: 0;
    float: left;
    margin-bottom: 20px;
}

.contentPage .flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.contentPage .text {
    max-width: 700px;
    /*margin-left: calc((50% - 500px) + 320px);*/
}

.contentPage .text p:first-child {
    margin-top: 0;
}

.contentPage .logo img {
    width: 100%;
}

.textBlock > .logo {
    display: inline-block;
    /*margin: 20px 40px 0;*/
    width: calc(20% - 80px);
    max-width: 120px;
    padding: 40px;
    padding-left: calc(50% - 460px);
}

.textBlock > .logo ~ .logo {
    padding-left: 40px;
}

.buttonLinks a {
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--accent-color);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    line-height: normal;
    text-decoration: none;
    padding: 10px 20px;
    margin: 8px 0;
}

.buttonLinks a:hover {
    background-color: #4d6ca5;
}


.copyright {
    background-color: #2c2c2c;
    color: white;
    text-align: center;
    /*height: 75px;*/
    line-height: 25px;
}

.copyright p {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 10px;
}

.copyright a {
    color: inherit;
    text-decoration: inherit;
}

.view3d {
    /*max-width: 1000px;
    margin-left: auto;
    margin-right: auto;*/
    padding-left: calc(50vw - 500px);
}

.singleView3D {
    width: 480px;
    height: 420px;
    display: inline-block;
    padding: 0 10px;
}

.singleView3D h2 {
    margin: 0;
    padding-left: 10px;
}

.singleView3D iframe {
    width: 480px;
    height: 360px;
    padding: 10px;
}

.contentExamplePage .text {
    max-width: 700px;
    /*min-width: 700px;*/
    /*margin-left: calc((50% - 500px) + 320px);*/
}
.fullMarkdownPage {
    background-color: var(--background-color);
    height: 100vh;
}

.loginMarkdownDiv {
    padding: 0px 10px;
    position: fixed;
    top: 0;
    right: 10px;
}

.languageSelect {
    padding: 5px;
    border-radius: 5px;
    margin: 12px 10px 0px;
}

.contentPageMd {
    background-color: white;
    overflow: auto;
    height: 100vh;
    font-size: 17px;
}

.contentPageMd p, 
.contentPageMd h1,
.contentPageMd h2,
.contentPageMd h3,
.contentPageMd h4, 
.contentPageMd ul, 
.contentPageMd ol,
.contentPageMd hr
{
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.contentPageMd ul, .contentPageMd ol {
    padding-left: 60px;
}

.contentPagePrice h3 {
    padding: 30px; 
}

.priceList {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.priceList table {
    width: 290px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
    background-color: white;
    color: black;
    padding: 10px;
    padding-top: 0px;
    border-radius: 10px;
    border-collapse: collapse;
    float: left;
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.breakFloat {
    clear: both;
}

.priceList table td {
    height: 25px;
    padding: 5px 10px;
}

.priceList table th {
    padding: 10px 10px;
    font-size: 20pt;
}

.priceList table td:nth-child(2) {
    text-align: right;
}

.priceList table tr:first-child th {
    border-radius: 10px 10px 0px 0px;
    background-color: var(--accent-color);
    color: white;
}

.priceList table tr:last-child td {
    border-radius: 0px 0px 10px 10px;
    padding-bottom: 10px;
}

.priceList img {
    width: 100%;
    padding-top: 20px;
    border-radius: 5px;
}

.center {
    text-align: center;
}

.backgroundPrice {
    background-image: linear-gradient(#657591b4, #4f70aa1f), url(/static/media/Pile.e188b28e.jpg);
    background-size: cover;
    background-position: center;
}
.react-cookie-law-dialog {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100000;
    background-color: transparent;
}

.react-cookie-law-container {
    background-color: rgb(240, 240, 240);
    padding: 10px;
    /*border-radius: 10px;*/
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.react-cookie-law-msg {
    min-height: 32px;
    font-size: 10pt;
    font-weight: 400;
    line-height: 130%;
    padding: 10px 0px;
    color: rgb(0, 0, 0);
}

.react-cookie-law-select-pane {
    display: table-cell;
    padding: 3px 0px 10px;
}

.react-cookie-law-option-wrapper {
    display: inline-block;
    position: relative;
    margin-right: 7px;
    margin-left: 7px;
    float: left;
}

.react-cookie-law-option-wrapper label {
    height: auto;
    width: auto;
    min-height: 14px;
    font-size: 12pt;
    color: rgb(198, 198, 198);
    display: inline-block;
    padding: 1px 0px 0px 20px;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 1;
    cursor: default;
    vertical-align: top;
}

.react-cookie-law-option-checkbox {
    position: absolute;
    top: 4px;
    left: 0px;
    width: 14px;
    height: 14px;
    z-index: 2;
    cursor: pointer;
}

.react-cookie-law-policy {
    font-size: 10pt;
    margin-left: 10px;
    color: rgb(0, 0, 0);
    text-decoration: underline;
}

.react-cookie-law-button-wrapper {
    float: right;
}

.react-cookie-law-accept-selection-btn {
    display: inline-block;
    background-color: var(--background-color);
    padding: 5px;
    min-width: 80px;
    color: rgb(240, 20, 20);
    border: 1px solid rgb(240, 20, 20);
    border-radius: 12px;
    text-decoration: none;
    font-size: 10pt;
    font-weight: 400;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}

.react-cookie-law-accept-all-btn {
    display: inline-block;
    background-color: var(--accent-color);
    padding: 5px;
    min-width: 80px;
    color: var(--background-color);
    border-radius: 12px;
    text-decoration: none;
    font-size: 10pt;
    font-weight: 400;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
}
.smallPopup-content, .confirmPopup-content {
    width: 250px;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
}

.smallPopup-content .bigButton, .confirmPopup-content .bigButton {
    margin: 0px 10px;
}

.mediumPopup-content {
    width: 500px;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
}

.popup-content {
    box-shadow:
        0.9px 1px 1.4px rgba(0, 0, 0, 0.096),
        2.1px 2.3px 3.5px rgba(0, 0, 0, 0.137),
        4px 4.4px 6.5px rgba(0, 0, 0, 0.17),
        7.1px 7.8px 11.6px rgba(0, 0, 0, 0.203),
        13.4px 14.6px 21.7px rgba(0, 0, 0, 0.244),
        32px 35px 52px rgba(0, 0, 0, 0.34)
    ;
}

.progressBar {
    background-color: lightgray;
    height: 12px;
    width: 90%;
    padding: 4px;
    margin: auto;
    border-radius: 10px;
}

.progressBar .progress {
    background-color: var(--accent-color);
    height: 12px;
    border-radius: 6px;
}


.gifPopup-content {
    width: 800px !important;
    height: 450px;
    z-index: 1002 !important;
}
  
.gifPopup-content img {
    width: 100%;
}
.loginPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(/static/media/background.7bfd1db8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.loginDiv {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow:
        2px 2px 1.4px rgba(0, 0, 0, 0.096),
        4.7px 4.9px 3.5px rgba(0, 0, 0, 0.137),
        8.9px 9.1px 6.5px rgba(0, 0, 0, 0.17),
        15.9px 16.3px 11.6px rgba(0, 0, 0, 0.203),
        29.7px 30.5px 21.7px rgba(0, 0, 0, 0.244),
        71px 73px 52px rgba(0, 0, 0, 0.34)
    ;
    position: relative;
}

.loginLogo {
    height: 100px;
    display: block;
    margin: auto;
    padding: 10px 0px 20px 0px;
}

.loginInput {
    padding: 12px 20px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: bold;
}

.loginFail {
    padding: 12px 20px;
    margin: 8px 0 0;
    border: 1px solid red;
    border-radius: 4px;
    color: red;
    font-size: 14px;
    background-color: rgba(255, 0, 0, 0.137);
}

.homeDiv {
    padding: 0px 20px;
}

.logoutDiv {
    padding: 0px 10px;
    position: fixed;
    top: 0;
    right: 0;
}

.header {
    background-color: #006FB7;
    height: 55px;
    color: white;
    font-weight: bold;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
}

.header label {
    padding: 0 10px;
    line-height: 50px;
}

.photomation {
    margin: 0;
}

.powered {
    margin: 10px 0 0 0;
    color: #929292;
}

.powered a:link{
    text-decoration: none;
}

.mapixLogo {
    font-family: 'Catamaran', sans-serif;
    font-weight: 900;
    color: #2A252C;
    background-color: var(--accent-color);
    padding: 5px 7px 7px 7px;
    border-radius: 5px;
    margin: 0 0 0 5px;
}

.userPopup-content {
    z-index: 1001 !important;
}

.userPopup-content .button {
    padding: 5px;
    width: calc(100% - 10px);
    text-align: center;
    margin: 2px 0px;
}

.loginButton {
    float: right;
}

.createButton {
    padding: 10px 10px !important;
    background-color: white;
    color: grey;
}

.createButton:hover {
    background-color: grey;
    color: white;
}
.overviewView {
    background-color: white;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: auto;
    max-width: 850px;
    margin: auto;
    padding: 10px;
    padding-top: 0px;
    height: calc(100vh - 75px);
    border: 10px solid transparent;
    border-radius: 20px;
}

.overviewPage {
    max-width: 800px;
    padding: 0 100px;
    margin: auto;
}

.overviewPage table {
    border-collapse: collapse;
    width: 550px;
}

.overviewPage table h1 .material-symbols-outlined {
    font-size: 30px;
    vertical-align: middle;
}
.overviewPage table h1 .text {
    font-size: 25px;
    vertical-align: middle;
    padding-left: 15px;
}

.overviewPage table tr { width: 100%; }
.overviewPage table .dataRow { background-color: white; }
.overviewPage table .dataRow:hover { background-color: rgb(233, 233, 233); }
.overviewPage table .dataRow td:first-child {
    font-weight: bold;
    margin: 5px 0;
    text-align: right;
    padding: 5px;
    width: 10%;
    border-radius: 7px;
    text-align: center;
    background-color: #dbe8ff;
}
.overviewPage table .dataRow td:not(:first-child) {
    height: 30px;
    width: 90%;
    padding: 0;
    border-radius: 7px;
}
.overviewPage table .spaceRow { height: 5px; }

.overviewPage table .dataRow td span{
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    height: calc(100%);
    width: calc(100% - 10px);
    border: none;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.projectView {
    background-color: white;
    min-width: 800px;
    width: calc(100% - 380px);
    height: calc(100vh - 75px);
    padding: 10px;
    padding-top: 0px;
    margin-left: 10px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: auto;
    border: 10px solid transparent;
    border-radius: 20px;
    display: inline-block;
}

.projectView.sbOpen {
    width: calc(100% - 650px);
    margin-left: 280px;
}

::-webkit-scrollbar             { width: 10px; }
::-webkit-scrollbar-track       { background: #d6d6d6; border-radius: 5px; }
::-webkit-scrollbar-thumb       { 
    background: #888888; 
    border: 3px solid transparent;
    border-radius: 9px; 
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover { 
    background: #555555;    
    border: 2px solid transparent;
    border-radius: 9px; 
    background-clip: content-box; 
}

.outputUpload {
    width: 360px;
    height: calc(100vh - 75px);
    display: inline-block;
    vertical-align: top;
}

.outputListView {
    background-color: white;
    width: 350px;
    height: calc(100vh - 375px);
    padding-top: 0px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    border: 10px solid transparent;
    border-radius: 20px;
    margin-left: 10px;
    display: block;
}

.uploadView {
    background-color: white;
    width: 350px;
    height: 290px;
    padding-top: 0px;
    box-sizing: border-box;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: none;
    border: 10px solid transparent;
    border-radius: 20px;
    margin-left: 10px;
    margin-top: 10px;
    display: block;
}


.projectname {
    position: fixed;
    top: -27px;
    left: 50%;
    z-index: 1002;
    transform: translateX(-50%);
    padding: 10px 20px;
    color: white;
}

.projectname h1 {
    display: inline-block;
}

.projectname button {
    margin: 0 10px;
    width: 35px;
    height: 35px;
    padding: 5px;
    color: #9b9b9b;
    background-color: inherit;
    transition: 0.2s;
    -webkit-transition: 0.2s;
}

.projectname button:hover {
    background-color: inherit !important;
    color: white;
}

.projectname button span {
    font-size: 25px;
}


/* Hamburger button */
.sbHamburger {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    padding: 12px 12px;
    color: white;
    width: 30px;
    height: 30px;
}
.sbHambBar1, .sbHambBar2, .sbHambBar3 {
    width: 30px;
    height: 4px;
    background-color: white;
    border-radius: 2px;
    transition: 0.4s ease;
    -webkit-transition: 0.4s ease;
    margin: 5px 0;
}
.sbHambBar1.sbBarOpen { transform: translate(0, 9px) rotate(-45deg); -webkit-transform: translate(0, 9px) rotate(-45deg); }
.sbHambBar2.sbBarOpen { opacity: 0; }
.sbHambBar3.sbBarOpen { transform: translate(0, -9px) rotate(45deg); -webkit-transform: translate(0, -9px) rotate(45deg); }


/* Buttons */
button, label.bigButton, .button {
    border: none;
    border-radius: 4px;
    color: white;
    background-color: var(--accent-color);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    line-height: normal;
    text-decoration: none;
}

button:hover, label.bigButton:hover, .button:hover {
    background-color: #4d6ca5;
}

button:disabled, button:disabled:hover, button:disabled:active, label.disabled, label.disabled:hover, label.disabled:active {
    color: white;
    background-color: #b6b6b6;
    cursor: default;
}

.hugeButton {
    padding: 20px 30px;
    margin: 18px 20px;
    font-size: 12pt;
    border-radius: 15px;
}

.bigButton {
    padding: 10px 20px;
    margin: 8px 0;
}

.smallButton {
    padding: 3px 7px;
    margin: 0 2px;
}

.darkButton       { 
    background-color: #00426e !important; 
}

.darkButton:hover { 
    background-color: #b6c1d6 !important; 
}

.iconButton {
    font-size: 20px;
    padding: 6px;
    line-height: 0.7;
    vertical-align: top;
    border-radius: 16px;
}

.helpButton {
    padding: 5px;
    color: #ffffff;
    background-color: #ffc273 !important; 
    position: absolute;
    right: 5px;
    top: 5px;
}

.helpButton:hover { 
    color: #ffffff;
    background-color: #ffa228 !important; 
}

.helpButton .material-symbols-outlined {
    font-size: 15px;
}

.helpButton.floatRight {
    position: revert;
    float: right;
    margin-right: 5px;
}

.helpButton.floatLeft {
    position: revert;
    float: left;
    margin-left: 5px;
}


.shadow {
    box-shadow:
        0px 0px 2.2px rgba(0, 0, 0, 0.037),
        0px 0px 5.3px rgba(0, 0, 0, 0.053),
        0px 0px 9.9px rgba(0, 0, 0, 0.065),
        0px 0px 17.6px rgba(0, 0, 0, 0.077),
        0px 0px 33px rgba(0, 0, 0, 0.093),
        0px 0px 79px rgba(0, 0, 0, 0.13)
    ;
}

/** Output table **/
.outputList {
    display: inline-block;
    vertical-align: top;
    overflow: auto;
    height: calc(100% - 54px);
}

.outputList table {
    border-collapse: collapse;
    width: 100%;
}

.outputList table tr:nth-child(2n + 1), .header2D { background-color: #f8f8f8; }
.outputList table tr:nth-child(2n), .header3D {     background-color: #ececec; }
.outputList table tr th { padding: 0 10px; }
.outputList table tr td { padding: 10px;   }
.outputList table tr td:last-child { text-align: right; }

.outputListView h1 { 
    margin: 0; 
    margin-bottom: 10px;
    text-align: center;
}
.outputListView h1 .material-symbols-outlined {
    font-size: 30px;
    vertical-align: middle;
}
.outputListView h1 .text {
    font-size: 25px;
    vertical-align: middle;
    padding-left: 15px;
}

.Collapsible {
    margin: 5px;
    width: 310px;
    background-color: #d5e5ff;
    border-radius: 10px;
}

.Collapsible__trigger {
    background-color: var(--accent-color);
    color: white;
    padding: 10px;
    padding-right: 40px;
    display: block;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
}

.Collapsible__trigger::after {
    font-family: 'FontAwesome';
    content: '▲';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 0.3s;
    -webkit-transition: transform 0.3s;
}

.Collapsible__trigger.is-open::after {
    transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
}
/** Options **/
.options {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    grid-template-rows: auto 200px 350px;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    min-height: 100%;
}

.options.tablet {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto 400px 250px;
}

.options.phone {
    grid-template-columns: 1fr;
    grid-template-rows: auto 200px 200px 400px 250px 200px;
}

.options > div { 
    vertical-align: top;
}
.options .optionsTable {
    border: none;
    min-height: 150px;
    border-radius: 10px;
    background: var(--accent-light);
    padding-top: 10px;
    padding-bottom: 10px;
}

.options .optionsTable:first-child {  margin-left: 0px; }
.options .optionsTable table       { margin: 0 auto; }

.optionsGroup { display: flex; }

.optionsCoordDist {  grid-column: 1 / 3;  grid-row: 1;     }
.optionsSelection {  grid-column: 1;      grid-row: 2;     }
.optionsPoints    {  grid-column: 2;      grid-row: 2;     }
.boundingboxMap   {  grid-column: 3;      grid-row: 1 / 4; }
.optionsGenerate  {  grid-column: 1;      grid-row: 3;     }
.optionsStart     {  grid-column: 2;      grid-row: 3;     }

.tablet .optionsSelection {  grid-column: 3;      grid-row: 1; }
.tablet .optionsPoints    {  grid-column: 4;      grid-row: 1; }
.tablet .boundingboxMap   {  grid-column: 1 / 5;  grid-row: 2; }

.phone .optionsCoordDist {  grid-column: 1;  grid-row: 1; }
.phone .optionsPoints    {  grid-column: 1;  grid-row: 3; }
.phone .boundingboxMap   {  grid-column: 1;  grid-row: 4; }
.phone .optionsGenerate  {  grid-column: 1;  grid-row: 5; }
.phone .optionsStart     {  grid-column: 1;  grid-row: 6; }


.mapContainer {
    width:  100%;
    height: 100%;
    border-radius: 10px;
}

.generateButton { margin: 20px; }
.cost { margin: 0 20px; }

.center { text-align: center; }
.shortInput { width: 75px; }

/** Info Popup **/
.info, .info:before, .infoSlide-content, .infoSlide-arrow {
    color: rgb(163, 125, 0);
}

.info, .infoSlide-content {
    background-color: rgb(252, 252, 171);
    border: 1px solid rgb(163, 125, 0);
    z-index: 1001 !important;
}

.info {
    padding: 5px 3px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    width: 30px;
}

.info:before {
    font-weight: bold;
    font-size: 15px;
    content: 'ⓘ';
    position: relative;
    padding: 7px;
    display: inline;
}

.alignRight { text-align: right; }

.triangleImg {
    width: 90%;
    padding: 2%;
}

td.min {
    width: 1%;
    white-space: nowrap;
}

td.right {
    text-align: right;
}

.smallText {
    font-size: 15px;
}
.sidebar {
    position: fixed;
    left: 0;
    top: 55px;
    background-color: var(--background-color);
    min-width: 250px;
    max-width: 250px;
    height: calc(100% - 55px);
    padding: 10px 10px;
    transition: 1.0s;
    -webkit-transition: 1.0s;
    z-index: 1001;
}
.sidebar.sbClosed {
    transform: translate(-100%, 0);
    -webkit-transform: translate(-100%, 0);
}

.sbExpand {
    max-width: 100%;
}

.topRow {
    margin-top: 3px;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 15px;
}
.topRow:hover { background-color: rgb(219, 219, 219); }

.activeProject {
    background-color: #cee0ff !important;
    font-weight: bold;
}

.sbTable {
    width: 100%;
    height: calc(100% - 200px);
    overflow: auto;
}

.sbTitle {
    display: inline-block;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 66px);
}

.sbState {
    display: block;
    width: 23px;
    min-height: 5px;
    text-align: center;
    float: left;
}
.sbState span   { font-size: 17px; padding: 3px; }
.sbStateDone    { color: green; }
.sbStateRunning { color: #5392ff; animation: rotation 3s infinite linear; }

@keyframes rotation {
    from { transform: rotate(359deg);   }
    to {   transform: rotate(0deg); }
}

.sbBtn {
    float: right;
    margin-top: 1px;
}

.sbProjBtn {
    height: 23px;
    width: 23px;
    padding: 2px;
    margin: 0px;
    border: none;
    border-radius: 14px;
    color: darkgrey;
    background-color: inherit;
    display: inline-block;
}
.sbProjBtn:hover { 
    background-color: darkgrey; 
    color: white;
}
.sbProjBtn span { font-size: 19px; }

.sbRotateBtn {
    width: 40px;
    height: 40px;
    transition: 0.4s;
    -webkit-transition: 0.4s;
    float: right;
    padding: 0;
    margin-top: 30px;
    background-color: inherit;
    color: darkgray;
    transform-origin: center;
    -webkit-transform-origin: center;
}
.sbRotateBtn .material-symbols-outlined {
    font-size: 40px;
}
.sbRotateBtn:hover {
    background-color: #b9b9b9 !important;
    color: white;
}

.rotatedBtn {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.newOpenProject {
    display: inline-block;
}

.smallerText {
    font-size: 12pt;
}
.uploadHeader {
    height: 40px;
    font-size: 16px;
    font-weight: bold;
}

.uploadList {
    height: calc(100% - 80px);
    overflow: auto;
}

.uploadAdd {
    height: 40px;
    overflow: auto;
}

.uploadAdd .info {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.uploadAdd .bigButton {
    vertical-align: middle;
    padding: 5px 10px;
    margin: 5px 5px;
}

.fileRow {
    margin-top: 3px;
    padding: 1px 5px;
    cursor: pointer;
    border-radius: 15px;
}
.fileRow:hover { background-color: rgb(219, 219, 219); }

.uploadState {
    display: block;
    width: 23px;
    min-height: 5px;
    text-align: center;
    float: left;
}

.uploadState span   { font-size: 17px; padding: 3px; }
.uploadStateDone    { color: green; }
.uploadStateRunning { color: #5392ff; animation: rotation 3s infinite linear; }

.uploadName {
    display: inline-block;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 73px);
}

.uploadBtn {
    float: right;
    margin-top: 1px;
}

.uploadFileBtn {
    height: 23px;
    width: 23px;
    padding: 2px;
    margin: 0px;
    border: none;
    border-radius: 14px;
    color: darkgrey;
    background-color: inherit;
    display: inline-block;
}
.uploadFileBtn:hover { 
    background-color: darkgrey; 
    color: white;
}
.uploadFileBtn span { font-size: 19px; }


.userView {
    background-color: white;
    max-width: 850px;
    margin: auto;
    padding: 10px;
    padding-top: 0px;
    height: calc(100vh - 75px);
    box-sizing: border-box;
    /*min-width: 800px;*/
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    overflow: auto;
    border: 10px solid transparent;
    border-radius: 20px;
}

.userCreateView {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.userPage {
    max-width: 800px;
    padding: 0 min(100px, 9%);
    margin: auto;
    overflow-y: auto;
    height: 100%;
}

.userPage table {
    border-collapse: collapse;
    width: 550px;
}

.userCreateView .userPage table {
    border-collapse: collapse;
    width: 650px;
}

.userPage table h1 .material-symbols-outlined {
    font-size: 30px;
    vertical-align: middle;
}
.userPage table h1 .text {
    font-size: 25px;
    vertical-align: middle;
    padding-left: 15px;
}

.userPage table tr { width: 100%; }
.userPage table .dataRow { background-color: #dbe8ff; }
.userPage table .dataRow td:first-child {
    font-weight: bold;
    margin: 5px 0;
    text-align: right;
    padding: 10px;
    width: 30%;
    border-top-left-radius:    7px;
    border-bottom-left-radius: 7px;
}
.userPage table .dataRow td:not(:first-child, .emptyColumn, .spaceColumn) {
    height: 40px;
    width: 35%;
    border-top-right-radius:    7px;
    border-bottom-right-radius: 7px;
    padding: 0;
}
.userPage table .dataRow .spaceColumn, .emptyColumn { background-color: white; }
.userPage table .dataRow .spaceColumn { width: 10px; }
.userPage table .dataRow .emptyColumn { width: 35%;  }
.userPage table .dataRow .errorMsg {
    width: calc(30% - 22px);
    height: 40px;
}
.userPage table .dataRow .errorMsg span {
    width: calc(100% - 5px);
    height: calc(100% - 2px);
    color: red;
    background: rgb(255, 201, 201);
    border-radius: 5px;
    border: 1px solid red;
    display: inline-flex;
    align-items: center;
    padding-left: 5px;
}
.userPage table .spaceRow { height: 10px; }

.editableInput {
    margin: 0;
    padding: 0;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    height: calc(100% - 2px);
    width: calc(100% - 12px);
    background: white;
    border: 1px solid darkgray;
    border-radius: 7px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.emptyInput {
    border: 1px solid red;
}

.notEditable {
    background: rgb(238, 238, 238);
    color: rgb(70, 70, 70);
}


@keyframes slideReveal {
    0% {display: none; transform: translateX(150%);}
    1% {display: block;}
    100% {transform: translateX(0%);}
}

.slideForm {
    display: block;
}

.visibleForm {
    animation-name: slideReveal;
    animation-duration: 1s;
}

.rightForm {
    display: none;
    transform: translateX(150%);
}

.leftForm {
    display: none;
    transform: translateX(-150%);
}
